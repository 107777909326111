
:root {
  --color-background: #161616;

  --color-primary: #a3a3ed;
  --color-primary-dark: #626287;

}

body {
  background-color: black;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  margin: 0;
  padding: 0;
}

.App {
  margin: 0 auto;
  background-color: var(--color-background);
  color: var(--color-primary);

  width: 500px;
  height: 200px;

  border-radius: 15px;
  border: solid 2px black;
}

h1 {
  text-shadow: var(--color-primary-dark) 0px 0px 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 3rem;
  padding-top: 8px;
  padding-left: 16px;
}

p {
  font-family: monospace;
  color: var(--color-primary-dark);
  margin: 16px;
  font-size: 1.2rem;
}